<template>
    <div class="app-container">
        <!-- <div class="app-top-btn">
            <el-button type="primary" size="medium" @click="myFormAdd()">
                新增商家
            </el-button>
            <el-button
                type="danger"
                size="medium"
                @click="excelOut"
                style="float: right"
            >
                导出Excel
            </el-button>
        </div> -->
        <el-table :data="list" border fit highlight-current-row>
            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column label="名称" prop="title" min-width="160">
            </el-table-column>
            <el-table-column
                align="center"
                label="编码"
                width="100"
                prop="code"
            >
            </el-table-column>
            <el-table-column align="center" label="分类" width="100">
                <template slot-scope="scope">
                    <span>{{
                        categoryList.filter(
                            (item) => item.id === scope.row.type
                        )[0] &&
                        categoryList.filter(
                            (item) => item.id === scope.row.type
                        )[0].title
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="90" label="折扣">
                <template slot-scope="scope">
                    <span>{{ scope.row.discount }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="90" label="服务费">
                <template slot-scope="scope">
                    <span>{{ scope.row.serviceCharge }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="90" label="退款">
                <template slot-scope="scope">
                    <span v-if="scope.row.refundSign === 1"> 不支持 </span>
                    <span v-else-if="scope.row.refundSign === 0"> 支持 </span>
                </template>
            </el-table-column>
            <!-- <el-table-column align="center" width="100" label="账号数量">
                <template slot-scope="scope">
                    <span>{{ scope.row.accountNum }}</span>
                </template>
            </el-table-column> -->
            <el-table-column align="center" width="110" label="商户收款码">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.qrcode"
                        type="success"
                        size="mini"
                        @click="openqrcode(scope.row.qrcode)"
                    >
                        点击查看
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column align="center" label="添加日期" width="160">
                <template slot-scope="scope">
                    <span>{{ scope.row.regTime }}</span>
                </template>
            </el-table-column>
            <el-table-column width="110" align="center" label="支持单位">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.rangeSign === 0"
                        type="primary"
                        size="mini"
                        disabled
                    >
                        所有单位
                    </el-button>
                    <el-button
                        v-else-if="scope.row.rangeSign === 1"
                        type="primary"
                        size="mini"
                        @click="unitShopEdit(scope.row)"
                    >
                        选择单位
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column align="center" width="90" label="状态">
                <template slot-scope="scope">
                    <el-button
                        type="success"
                        size="mini"
                        v-if="scope.row.status == 1"
                        @click="switchStatus('确定禁用吗', scope.row.id, 0)"
                    >
                        已启用
                    </el-button>
                    <el-button
                        type="danger"
                        size="mini"
                        v-if="scope.row.status == 0"
                        @click="switchStatus('确定启用吗', scope.row.id, 1)"
                    >
                        已禁用
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column
                width="180"
                align="center"
                label="操作"
                fixed="right"
            >
                <template slot-scope="scope">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="myFormEdit(scope.row)"
                    >
                        编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--分页-->
        <div class="app-pagination">
            <el-pagination
                background
                layout="total,prev, pager, next"
                :total="listTotal"
                :page-size="listQuery.pageSize"
                :current-page="listQuery.pageNum"
                @current-change="pageNumChange"
            >
            </el-pagination>
        </div>

        <!--新增/编辑-->
        <el-dialog
            :title="myFormTitle"
            :visible.sync="myFormDialog"
            width="80%"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <el-form
                :model="myFormFields"
                :rules="myFormRules"
                ref="myForm"
                label-width="120px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="商家编码" prop="storeCode">
                            <el-input
                                v-model="myFormFields.storeCode"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商家名称" prop="title">
                            <el-input v-model="myFormFields.title"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商家类别" prop="type">
                            <el-select
                                v-model="myFormFields.type"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in categoryList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id"
                                    placeholder="请选择分类"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="商家地址" prop="address">
                            <el-input v-model="myFormFields.address"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系人" prop="contactName">
                            <el-input
                                v-model="myFormFields.contactName"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话" prop="contactPhone">
                            <el-input
                                v-model="myFormFields.contactPhone"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="统一社会编码" prop="ucCode">
                            <el-input v-model="myFormFields.ucCode"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注" prop="about">
                            <el-input v-model="myFormFields.about"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="银行账号" prop="bankCardno">
                            <el-input
                                v-model="myFormFields.bankCardno"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开户行" prop="bankName">
                            <el-input
                                v-model="myFormFields.bankName"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开户名称" prop="bankOwner">
                            <el-input
                                v-model="myFormFields.bankOwner"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="聚富通编码" prop="jftCode">
                            <el-input
                                v-model="myFormFields.jftCode"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否在H5展示" prop="h5Show">
                            <el-radio-group v-model="myFormFields.h5Show">
                                <el-radio :label="0">不展示</el-radio>
                                <el-radio :label="1">展示</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="单位" prop="rangeSign">
                            <el-radio-group v-model="myFormFields.rangeSign">
                                <el-radio :label="0">所有单位</el-radio>
                                <el-radio :label="1">自行选择</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="退款" prop="refundSign">
                            <el-radio-group v-model="myFormFields.refundSign">
                                <el-radio :label="0">支持</el-radio>
                                <el-radio :label="1">不支持</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="排序" prop="sort">
                            <el-input-number
                                v-model="myFormFields.sort"
                                :precision="0"
                                :step="1"
                                :min="0"
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="折扣" prop="discount">
                            <el-input-number
                                v-model="myFormFields.discount"
                                :precision="2"
                                :step="0.01"
                                :max="1"
                                :min="0.01"
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="服务费" prop="serviceCharge">
                            <el-input-number
                                v-model="myFormFields.serviceCharge"
                                :precision="3"
                                :step="0.001"
                                :max="1"
                                :min="0"
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="LOGO" prop="logo">
                            <ImageUpload
                                :count="1"
                                :list="
                                    myFormFields.logo ? [myFormFields.logo] : []
                                "
                                @upload-success="
                                    (url) => {
                                        $set(myFormFields, 'logo', url[0])
                                    }
                                "
                                :width="750"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="图片" prop="icon">
                            <div
                                class="app-upload-img"
                                v-for="(item, index) in myFormFields.picList"
                                :key="index"
                            >
                                <el-image
                                    :src="item"
                                    :preview-src-list="myFormFields.picList"
                                    fit="contain"
                                >
                                </el-image>
                                <el-button
                                    type="danger"
                                    icon="el-icon-close"
                                    size="mini"
                                    circle
                                    @click="delPicList(index)"
                                >
                                </el-button>
                            </div>
                            <el-upload
                                class="app-upload"
                                action="#"
                                list-type="picture-card"
                                :show-file-list="false"
                                :auto-upload="false"
                                :on-change="uploadPicList"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item style="margin: 0">
                    <el-button type="primary" @click="myFormSubmit">
                        提交
                    </el-button>
                    <el-button @click="myFormReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--选择单位/商家-->
        <el-dialog
            title="选择单位"
            :visible.sync="unitShopDialog"
            width="500px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
            style="overflow: hidden"
        >
            <el-input
                placeholder="输入关键字进行过滤"
                v-model="unitShopFilterText"
            >
            </el-input>
            <div style="overflow-y: auto; height: 30vh">
                <el-tree
                    style="margin: 20px 0 0 0"
                    show-checkbox
                    highlight-current
                    default-expand-all
                    node-key="id"
                    ref="unitShopTree"
                    :data="unitShopList"
                    :default-checked-keys="unitShopFields.ids"
                    :props="unitShopDefaultProps"
                    :filter-node-method="unitShopFilterNode"
                >
                </el-tree>
            </div>

            <div style="margin: 20px 0 0 0; text-align: center">
                <el-button type="primary" size="medium" @click="unitShopSubmit">
                    提交
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Export2Excel } from '@/utils/excel'
import pinyin from 'js-pinyin'
pinyin.setOptions({ checkPolyphone: false, charCase: 0 })
export default {
    watch: {
        //选择单位/商家 - 关键字过滤
        unitShopFilterText(val) {
            this.$refs.unitShopTree.filter(val)
        },
    },
    data() {
        return {
            getLongitude: '',
            getLatitude: '',
            list: null,
            listTotal: 0,
            listQuery: {
                pageNum: 1,
                pageSize: 10,
            },
            categoryList: [], //分类列表
            myFormType: true, //true为新增，false为编辑
            myFormTitle: '新增',
            myFormDialog: false,
            myFormFields: {},
            myFormRules: {
                title: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                type: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                address: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                contactName: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                contactPhone: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                ucCode: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                // logo: [
                //     {
                //         required: true,
                //         message: '必填',
                //         trigger: 'blur',
                //     },
                // ],
                rangeSign: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                refundSign: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                discount: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                serviceCharge: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
            },
            unitShopList: [],
            unitShopDialog: false,
            unitShopFields: {
                type: 2,
                id: 0,
                ids: [],
            },
            unitShopDefaultProps: {
                children: 'children',
                label: 'title',
            },
            unitShopFilterText: '',
            accountFormDialog: false,
            accountFormFields: {},
            accountFormRules: {
                username: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                name: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {
        //获取列表
        this.getList()
    },

    methods: {
        // 获取位置
        getLocation() {
            var _this = this
            if (navigator.geolocation) {
                console.log(
                    'navigator.geolocation',
                    navigator.geolocation,
                    this.getLongitude,
                    this.getLatitude
                )
                navigator.geolocation.getCurrentPosition(
                    //locationSuccess 获取成功的话
                    function (position) {
                        _this.getLongitude = position.coords.longitude
                        _this.getLatitude = position.coords.latitude
                        alert(_this.getLongitude) //弹出经度测试
                        alert(_this.getLatitude)
                    },
                    //locationError  获取失败的话
                    function (error) {
                        var errorType = [
                            '您拒绝共享位置信息',
                            '获取不到位置信息',
                            '获取位置信息超时',
                        ]
                        alert(errorType[error.code - 1])
                    }
                )
            }
        },

        //分页处理
        pageNumChange(page) {
            if (page != this.listQuery.pageNum) {
                this.listQuery.pageNum = page
                this.getList()
            }
        },
        //查询关键字
        searchKeyWords() {
            this.listQuery.pageNum = 1
            this.getList()
        },
        //获取分类列表
        getCategoryList() {
            this.$api.store.store_category_select().then((res) => {
                console.log(res, 'categoryListcategoryListcategoryList')
                this.categoryList = res
            })
        },
        //获取列表
        getList() {
            this.$api.store.store_page(this.listQuery).then((res) => {
                console.log('storellist', res)
                this.listTotal = res.pageInfo.total
                this.list = res.data
            })
        },
        //启用禁用
        switchStatus(title, id, status) {
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$api.store
                    .store_updateStatus({
                        id: id,
                        status: status,
                    })
                    .then((res) => {
                        this.$message({
                            duration: 1000,
                            type: 'success',
                            message: res,
                        })
                        this.getList()
                    })
            })
        },
        //新增/编辑 - 新增
        myFormAdd() {
            //定义参数
            this.myFormFields = {
                name: '',
                type: '',
                address: '',
                contactName: '',
                contactPhone: '',
                about: '',
                ucCode: '',
                lat: '',
                lng: '',
                logo: '',
                picList: [],
                rangeSign: 0, //0支持所有单位,1自行选择单位
                refundSign: 0, //退款标识，0可以，1不可以
                discount: 1, //折扣
                sort: 100, //排序
                serviceCharge: 0, //服务费
                code: '',
                storeCode: '',
            }
            //显示弹窗
            this.myFormTitle = '新增'
            this.myFormType = true //true为新增，false为编辑
            this.myFormDialog = true
        },
        //新增/编辑 - 编辑
        myFormEdit(item) {
            console.log(item)
            //定义参数
            let item_copy = Object.assign({}, item)
            this.myFormFields = item_copy
            //显示弹窗
            this.myFormTitle = '编辑'
            this.myFormType = false //true为新增，false为编辑
            this.myFormDialog = true
        },
        //新增/编辑 - 表单重置
        myFormReset() {
            this.$refs['myForm'].resetFields()
        },
        //新增/编辑 - 表单提交
        myFormSubmit() {
            this.$refs['myForm'].validate((valid) => {
                if (valid) {
                    let self = this
                    if (this.myFormType) {
                        this.$api.store
                            .store_save(this.myFormFields)
                            .then((res) => {
                                self.$message({
                                    duration: 1000,
                                    type: 'success',
                                    message: res,
                                })
                                self.myFormDialog = false
                                self.getList()
                            })
                    } else {
                        this.$api.store
                            .store_update(this.myFormFields)
                            .then((res) => {
                                self.$message({
                                    duration: 1000,
                                    type: 'success',
                                    message: res,
                                })
                                self.myFormDialog = false
                                self.getList()
                            })
                    }
                }
            })
        },
        //上传图片
        uploadPicList(file) {
            const data = new FormData()
            data.append('file', file.raw)
            this.$api.upload(data).then((res) => {
                this.$message({
                    duration: 1000,
                    type: 'success',
                    message: '上传成功',
                })
                this.myFormFields.picList.push(res)
            })
        },
        //移除图片
        delPicList(index) {
            this.$confirm('确定移除该图片吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$message({
                    duration: 1000,
                    type: 'success',
                    message: '移除成功',
                })
                this.myFormFields.picList.splice(index, 1) //（指定下标开始，删除1个）
            })
        },
        //选择单位/商家 - 拉取列表
        getUnitShopList() {
            this.$api.store.department_select().then((res) => {
                this.unitShopList = res
            })
        },
        //选择单位/商家 - 编辑
        unitShopEdit(item) {
            this.getUnitShopList()
            this.unitShopFields.id = item.id
            this.$api.store
                .allowPay_list({
                    type: this.unitShopFields.type,
                    id: this.unitShopFields.id,
                })
                .then((res) => {
                    this.unitShopFields.ids = res
                    this.unitShopDialog = true
                })
        },
        //选择单位/商家 - 关键字过滤
        unitShopFilterNode(value, data) {
            if (!value) return true
            return data.title.indexOf(value) !== -1
        },
        //选择单位/商家 - 提交
        unitShopSubmit() {
            this.unitShopFields.ids = this.$refs.unitShopTree.getCheckedKeys()
            this.$api.store.allowPay_bind(this.unitShopFields).then((res) => {
                this.$message({
                    duration: 1000,
                    type: 'success',
                    message: res,
                })
                this.unitShopDialog = false
            })
        },
        //导出excel
        excelOut() {
            const loading = this.$loading({
                lock: true,
                text: '数据下载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            this.$api.store
                .store_list(this.listQuery)
                .then((res) => {
                    console.log(res)
                    if (res.length > 0) {
                        for (let index = 0; index < res.length; index++) {
                            if (res[index].status == 0) {
                                res[index].status = '禁用'
                            } else {
                                res[index].status = '启用'
                            }
                        }
                        Export2Excel(
                            '商家列表',
                            [
                                '商家类别',
                                '联系人',
                                '统一社会编码',
                                '折扣',
                                '商家名称',
                                '商家地址',
                                '联系电话',
                                '备注',
                                '农商行编码',
                            ],
                            [
                                'typeName',
                                'contactName',
                                'ucCode',
                                'discount',
                                'name',
                                'address',
                                'contactPhone',
                                'about',
                                'code',
                            ],
                            res
                        )
                        loading.close()
                    } else {
                        this.$message({
                            duration: 1000,
                            message: '没有可导出的数据',
                            type: 'error',
                        })
                        loading.close()
                        return false
                    }
                })
                .catch(() => {
                    loading.close()
                })
        },
        openqrcode(url) {
            window.open(url)
        },
    },
}
</script>
